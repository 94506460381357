import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { initTranslations } from '../../../i18n/i18n.js';

const CitySearch = ({ companies }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const { t } = initTranslations();

  useEffect(() => {
    if (Array.isArray(companies) && searchTerm.trim() !== '') {
      const results = companies.filter(company => {
        const city = company.companyAddress && company.companyAddress[0] && company.companyAddress[0].City;
        return city && city.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredCompanies(results);
    } else {
      setFilteredCompanies([]);
    }
  }, [searchTerm, companies]);

  if (!Array.isArray(companies)) {
    return <p>{t('common:loading')}</p>;
  }

  const getCompanyName = (company) => {
    return company.companyDetails && company.companyDetails[0] && company.companyDetails[0].CompanyName 
      || company.Name 
      || t('common:unknown-company');
  };

  const getCompanyLogo = (company) => {
    const logo = company.companyDetails && company.companyDetails[0] && company.companyDetails[0].CompanyLogo;
    return logo ? `data:image/png;base64,${logo}` : '';
  };

  return (
    <div className="city-search-container">
      <input
        type="text"
        placeholder={t('common:search-by-city')}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="form-control"
      />
      <div className="horizontal-scroll-container mt-auto bottom-0 mb-2 mt-0">
        {searchTerm.trim() !== '' && filteredCompanies.length > 0 ? (
          filteredCompanies.map((company) => (
            <div key={company.Id || company.id} className="card company-card p-0">
                <div className="card-header Mineral">
                    <h5>{getCompanyName(company)}</h5>
                </div>
                <div className="card-body">
                    <center>
                        <img 
                src={getCompanyLogo(company)} 
                className="img-md" 
              />
              <h5>{getCompanyName(company)}</h5>
              </center>
              
            </div>
            <div className="card-footer">
              <a href={`/${company.CompanyId || company.CompanyId}`} className="btn btn-primary">
                {t('common:view-company-details')}
              </a>
            </div>
            </div>
          ))
        ) : searchTerm.trim() !== '' ? (
          <p>{t('common:no-results')}</p>
        ) : null}
      </div>
    </div>
  );
};

CitySearch.propTypes = {
  companies: PropTypes.array
};

CitySearch.defaultProps = {
  companies: []
};

export default CitySearch;
