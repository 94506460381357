import axios from 'axios';

export async function getCompanyAddress(companyId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SSO_URL}/company-auth/api-company-address/filter?CompanyId=${companyId}`);
        const addresses = response.data;
        console.log("Addresses from SSO for company", companyId, ":", addresses);

        if (addresses && addresses.length > 0) {
            const address = addresses[0];
            
            const geocodedAddress = await geocodeAddressWithRetry(address);
            console.log("Geocoded address for company", companyId, ":", geocodedAddress);
            
            return {
                ...address,
                latitude: geocodedAddress.latitude,
                longitude: geocodedAddress.longitude
            };
        } else {
            throw new Error('No address found for the company');
        }
    } catch (error) {
        console.error('Error fetching company address:', error);
        return {};
    }
}

const geocodeCache = new Map();

const geocodeWithNominatim = async (query) => {
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`;
    const response = await axios.get(url);
    if (response.data && response.data.length > 0) {
        return {
            latitude: parseFloat(response.data[0].lat),
            longitude: parseFloat(response.data[0].lon)
        };
    }
    throw new Error('No results found with Nominatim');
};

export const geocodeAddress = async (address) => {
    const cacheKey = JSON.stringify(address);
    if (geocodeCache.has(cacheKey)) {
        return geocodeCache.get(cacheKey);
    }

    const query = `${address.Address1}, ${address.ZipCode}, ${address.City}, ${address.Country}`;

    try {
        const result = await geocodeWithNominatim(query);
        geocodeCache.set(cacheKey, result);
        return result;
    } catch (error) {
        console.error('Error geocoding address:', error);
        throw error;
    }
};

export const geocodeAddressWithRetry = async (address, maxRetries = 3, delay = 1000) => {
    for (let i = 0; i < maxRetries; i++) {
        try {
            return await geocodeAddress(address);
        } catch (error) {
            if (i === maxRetries - 1) throw error;
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }
};
