import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import { initTranslations } from "../../../i18n/i18n.js";
import { geocodeAddressWithRetry } from "../../../data/services/sso-service.js";
import { Spinner } from 'react-bootstrap';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '../../../styles/world-map.css';

// Modifiez le style pour ajuster l'animation d'onde
const markerStyle = `
    @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.2); }
        100% { transform: scale(1); }
    }
    @keyframes ripple {
        0% { box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7); }
        100% { box-shadow: 0 0 0 20px rgba(0, 123, 255, 0); }
    }
    .custom-icon-inner {
        animation: pulse 2s infinite, ripple 2s infinite;
    }
    .custom-cluster-icon-inner {
        animation: pulse 2s infinite, ripple 2s infinite;
    }
`;

function SetBoundsComponent({ bounds }) {
    const map = useMap();
    useEffect(() => {
        if (bounds) {
            map.fitBounds(bounds, { padding: [50, 50] });
        }
    }, [map, bounds]);
    return null;
}

SetBoundsComponent.propTypes = {
    bounds: PropTypes.instanceOf(L.LatLngBounds)
};

const WorldMap = ({ companies, onCompanySelect }) => {
    const { t } = initTranslations();
    const [mapCenter, setMapCenter] = useState([48.8566, 2.3522]); // Paris by default
    const [companiesWithCoords, setCompaniesWithCoords] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [mapBounds, setMapBounds] = useState(null);
    const markerClusterGroupRef = useRef(null);
    const [visibleCompanies, setVisibleCompanies] = useState([]);
    const mapRef = useRef(null);

    // Parse the REACT_APP_IGNORE_CPY_ID environment variable
    const ignoredCompanyIds = useMemo(() => {
        try {
            return JSON.parse(process.env.REACT_APP_IGNORE_CPY_ID || '[]');
        } catch (error) {
            console.error('Error parsing REACT_APP_IGNORE_CPY_ID:', error);
            return [];
        }
    }, []);

    // Pré-créer le contenu des popups
    const popupContents = useMemo(() => {
        return companiesWithCoords.reduce((acc, company) => {
            if (company.coordinates?.latitude && company.coordinates?.longitude) {
                acc[company.CompanyId] = createPopupContent(company, t);
            }
            return acc;
        }, {});
    }, [companiesWithCoords, t]);

    const [geocodingErrors, setGeocodingErrors] = useState([]);
    const [networkError, setNetworkError] = useState(false);

    useEffect(() => {
        const geocodeCompanies = async () => {
            setIsLoading(true);
            setNetworkError(false);
            setGeocodingErrors([]);
            const activeCompanies = companies.filter(company => 
                company.IsActive === 1 && !ignoredCompanyIds.includes(company.CompanyId)
            );
            const companiesWithCoordinates = await Promise.all(
                activeCompanies.map(async (company) => {
                    if (company.companyAddress && company.companyAddress.length > 0) {
                        const address = company.companyAddress[0];
                        if (address) {
                            try {
                                const coords = await geocodeAddressWithRetry(address);
                                return { ...company, coordinates: coords };
                            } catch (error) {
                                console.error(`Error geocoding address for company ${company.CompanyId}:`, error);
                                if (error.message === 'Network Error') {
                                    setNetworkError(true);
                                }
                                setGeocodingErrors(prev => [...prev, company.CompanyId]);
                                // Fallback to using a default coordinate or the company's stored coordinates
                                return { 
                                    ...company, 
                                    coordinates: company.Latitude && company.Longitude 
                                        ? { latitude: company.Latitude, longitude: company.Longitude }
                                        : null 
                                };
                            }
                        }
                    }
                    return { ...company, coordinates: null };
                })
            );

            const validCompanies = companiesWithCoordinates.filter(c => c.coordinates && c.coordinates.latitude && c.coordinates.longitude);
            setCompaniesWithCoords(validCompanies);
            
            if (validCompanies.length > 0) {
                const bounds = L.latLngBounds(validCompanies.map(c => [c.coordinates.latitude, c.coordinates.longitude]));
                setMapBounds(bounds);
                const center = bounds.getCenter();
                setMapCenter([center.lat, center.lng]);
            } else {
                // Fallback to a default center if no valid companies
                setMapCenter([48.8566, 2.3522]); // Paris
            }
            setIsLoading(false);
        };

        geocodeCompanies();
    }, [companies, ignoredCompanyIds]);

    useEffect(() => {
        if (mapRef.current && markerClusterGroupRef.current) {
            const map = mapRef.current;
            const updateVisibleMarkers = () => {
                const bounds = map.getBounds();
                const visibleCompanies = companiesWithCoords.filter(company => {
                    const { latitude, longitude } = company.coordinates;
                    return bounds.contains([latitude, longitude]);
                });
                setVisibleCompanies(visibleCompanies);
            };

            map.on('moveend', updateVisibleMarkers);
            map.on('zoomend', updateVisibleMarkers);

            // Initial update
            updateVisibleMarkers();

            return () => {
                map.off('moveend', updateVisibleMarkers);
                map.off('zoomend', updateVisibleMarkers);
            };
        }
    }, [companiesWithCoords]);

    const handleMarkerClick = (company, marker) => {
        if (onCompanySelect) {
            onCompanySelect(company);
        }
        // Ouvrir le popup
        marker.openPopup();
    };

    useEffect(() => {
        if (markerClusterGroupRef.current) {
            markerClusterGroupRef.current.clearLayers();
            visibleCompanies.forEach((company) => {
                if (company.coordinates?.latitude && company.coordinates?.longitude) {
                    const companyLogo = company.companyDetails[0]?.CompanyLogo 
                        !=null && company.companyDetails[0]?.CompanyLogo 
                        !=""? `url(data:image/png;base64,${company.companyDetails[0].CompanyLogo})`
                        : require("../../../rits-package/img/logos/MO2-circle.png");

                    const customIcon = L.divIcon({
                        className: 'custom-icon',
                        html: `<div class="custom-icon-inner" style="
                            width: 60px; 
                            height: 60px; 
                            background-color: transparent;
                            border-radius: 50%; 
                            overflow: hidden;
                            background-image: ${companyLogo};
                            border: 2px solid #007bff;
                            background-size: cover;
                            background-position: center;
                        "></div>`,
                        iconSize: [60, 60],
                        iconAnchor: [30, 60],
                        popupAnchor: [0, -60]
                    });

                    const marker = L.marker([company.coordinates.latitude, company.coordinates.longitude], { icon: customIcon });
                    
                    const popup = L.popup().setContent(popupContents[company.CompanyId]);
                    marker.bindPopup(popup);

                    marker.on('mouseover', function () {
                        if (!L.Browser.mobile) {
                            this.openPopup();
                        }
                    });

                    marker.on('click', () => {
                        handleMarkerClick(company, marker);
                        marker.openPopup();
                    });

                    // Add touch event for mobile devices
                    marker.on('touchstart', () => {
                        handleMarkerClick(company, marker);
                        marker.openPopup();
                    });

                    markerClusterGroupRef.current.addLayer(marker);
                }
            });
        }
    }, [visibleCompanies, popupContents]);

    function createPopupContent(company, t) {
        const address = company.companyAddress && company.companyAddress[0];
        let fullAddress = '';
        if (address) {
            fullAddress = address.Address1;
            if (address.Address2 && address.Address2 !== 'null') {
                fullAddress += "<br>" + address.Address2;
            }
            if (address.Address3 && address.Address3 !== 'null') {
                fullAddress += "<br>" + address.Address3;
            }
            fullAddress += `<br>${address.ZipCode}, ${address.City}`;
            if (address.PhoneNumber) {
                fullAddress += `<br>${address.PhoneNumber}`;
            }
        }

        const companyLogo = company.companyDetails[0]?.CompanyLogo 
            ? `data:image/png;base64,${company.companyDetails[0].CompanyLogo}`
            : require("../../../rits-package/img/logos/MO2-circle.png");
        
        const companyName = company.companyDetails[0]?.CompanyName || t('common:unknown-company');

        return `
            <div class="company-card p-0 no-border" style="width: 250px; border:none!important;margin-left:15px">
                <div class="card-header">
                    <h4>${companyName}</h4>
                </div>
                <div class="card-body">
                    <center>
                        <img src="${companyLogo}" alt="${companyName}" class="img-md" style="width: 80px; height: 80px; object-fit: contain;" />
                        
                        <p>${fullAddress}</p>
                    </center>
                </div>
                <div class="card-footer">
                    <button onclick="window.handlePopupButtonClick(${company.CompanyId})" class="btn btn-primary">
                        ${t("common:view-company-details")}
                    </button>
                </div>
            </div>
        `;
    }

    function MarkerClusterGroupComponent() {
        const map = useMap();
        
        useEffect(() => {
            markerClusterGroupRef.current = L.markerClusterGroup({
                iconCreateFunction: function(cluster) {
                    return L.divIcon({
                        html: `
                            <div class="custom-cluster-icon-wrapper" style="
                                width: 40px;
                                height: 40px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            ">
                                <div class="custom-cluster-icon-inner" style="
                                    background-color: #007bff;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: white;
                                    font-weight: bold;
                                    font-size: 18px;
                                ">${cluster.getChildCount()}</div>
                            </div>
                        `,
                        className: 'custom-cluster-icon',
                        iconSize: L.point(80, 80),
                        iconAnchor: L.point(40, 40)
                    });
                }
            });
            map.addLayer(markerClusterGroupRef.current);

            return () => {
                if (map && markerClusterGroupRef.current) {
                    map.removeLayer(markerClusterGroupRef.current);
                }
            };
        }, [map]);

        return null;
    }

    useEffect(() => {
        // Ajouter la fonction handlePopupButtonClick à l'objet window
        window.handlePopupButtonClick = (companyId) => {
            window.location.href = `/${companyId}`;
        };

        // Nettoyer la fonction lors du démontage du composant
        return () => {
            delete window.handlePopupButtonClick;
        };
    }, []);

    return (
        <div style={{ position: 'relative', height: '65vh', width: '100%' }}>
            <style>{markerStyle}</style>
            {isLoading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            {(geocodingErrors.length > 0 || networkError) && (
                <div style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '10px',
                    borderRadius: '5px',
                    zIndex: 1000
                }}>
                    {networkError ? (
                        <p>Network error occurred. Some locations may not be accurate.</p>
                    ) : (
                        <p>Some addresses couldn&apos;t be geocoded. The map may be incomplete.</p>
                    )}
                </div>
            )}
            <MapContainer 
                center={mapCenter} 
                zoom={5} 
                style={{ height: '100%', width: '100%' }}
                ref={mapRef}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <SetBoundsComponent bounds={mapBounds} />
                <MarkerClusterGroupComponent />
            </MapContainer>
        </div>
    );
};

WorldMap.propTypes = {
    companies: PropTypes.array.isRequired,
    onCompanySelect: PropTypes.func
};

export default WorldMap;
