/**
 * ! Change LegalsComponent by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";

const LegalsComponent = (_props) => {
	var props = _props.properties;
	const componentId = "LegalsComponent-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	function componentDidMount() {
		try {
			setLoadedState(true);
			var data = "todo";
			setLoadFunction(data);
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}
	// Execute after load until all is ok.
	function setLoadFunction(data) {
		try {
			if (data != null) {
				//Something with datz
			}
		} catch {
			setTimeout(() => {
				setLoadFunction(data);
			}, 50);
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(`${error}`, "error", t("error", { ns: props.trNamespace }));
	}

	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}

	return loaded ? (
		props.show ? (
			<div className="app-side-margins mt-4 mb-4" style={{ bottom: "80" }}>
				<div className="row" id={componentId}>
					<div className="col-sm-12 offset-md-4 col-md-4 ">
						<div className="card">
							<div className="card-header bg-secondary-light">
								<b><i >{t("common:legals-alcohol-title")}</i></b>
							</div>
							<div className="card-body bg-secondary-light border-rounded">
								<p>
									<i>{t("common:legals-alcohol")}</i>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div >) : null

	) : null;
};

LegalsComponent.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	show: PropTypes.bool,
};

export default LegalsComponent;
